// import PropTypes from 'prop-types';
// material-ui
import { Chip, Hidden, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "actions";
import { INSTRUCTIONS, REACT_APP_LOGO } from "config";
import InteractionPreview from "hooks/InteractionPreview";
import Pipeline from "hooks/Pipeline";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);
export default function App() {
  const pipeline = useSelector((state) => state.pipeline);
  const classes = useStyles(styles);
  const dispatch = useDispatch();
  const pipelineGraph = useSelector((state) => state.pipelineGraph);
  const id = 1;

  React.useEffect(() => {
    if (id) {
      dispatch(Actions.getPipelineGraphById(id));
      dispatch(Actions.getPipelineById(id));
    }
  }, [id]);

  function handleRestart() {
    dispatch(Actions.setSelectedNode(null));
    dispatch(Actions.setSelectedInteractor(null));
    dispatch(Actions.setDisplayNode(null));
    dispatch(Actions.setSelectedNextStage(null));
    dispatch(Actions.setHistory({ nodes: [], interactors: [] }));
  }

  return (
    <div className={`${classes.root}`}>
      <div vertical className={`${classes.container} dotted`}>
        <Hidden smDown>
          <div className={classes.item1} style={{ flex: 1 }}>
            <div className={classes.pipeline}>
              <Pipeline pipeline={pipelineGraph} debug />
            </div>
          </div>
        </Hidden>
        <div
          className={classes.item2}
          // style={{ transform: "scale(0.9)", transformOrigin: "50% 100%" }}
        >
          <InteractionPreview />
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          top: 20,
          textAlign: "center",
          width: "100%",
        }}
      >
        <Typography variant="h4" gutterBottom>
          <b>{INSTRUCTIONS}</b>
        </Typography>
        <Hidden smUp>
          <Chip
            size="small"
            onClick={handleRestart}
            style={{ background: "#f44336", color: "white" }}
            label={
              <span>
                <i class="fas fa-undo"></i> Restart Simulation
              </span>
            }
          />
        </Hidden>
      </div>
      <Hidden xsDown>
        <div
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={REACT_APP_LOGO} style={{ height: 25 }} />
        </div>
      </Hidden>
    </div>
  );
}
