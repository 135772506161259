import { SET_HISTORY } from "constants/app";

export default (state = { nodes: [], interactors: [] }, action) => {
  switch (action.type) {
    case SET_HISTORY:
      return action.value;
    default:
      return state;
  }
};
