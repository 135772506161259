/*
    @flow

    global document Math
    SyntheticWheelEvent SyntheticMouseEvent WheelEvent MouseEvent 
*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Edge from "../Edge";
import Node from "../Node";
import styles from "./styles.js";

const SCALE_MAX = 1;
const SCALE_MIN = 0.3;
const SCALE_STEP = 0.1;

export { Node, Edge };

const mapStateToProps = (state) => ({
  selectedInteractor: state.selectedInteractor,
  selectedNextStage: state.selectedNextStage,
  selectedNode: state.selectedNode,
  history: state.history,
});

const mapDispatchToProps = (dispatch) => ({});

class Graph extends Component {
  static defaultProps = {
    scale: 1,
    minScale: 1,
    maxScale: 1,
    width: 600,
    height: 400,
    style: {},
  };

  constructor(props) {
    super(props);

    this.parentWidth = document.body ? document.body.clientWidth : 0;
    this.nodeComponents = [];

    const minScale = props.minScale
      ? Math.max(props.minScale, SCALE_MIN)
      : SCALE_MIN;
    const maxScale = props.maxScale
      ? Math.min(props.maxScale, SCALE_MAX)
      : SCALE_MAX;

    const viewOffsetX = props.width * (minScale - maxScale);
    const viewOffsetY = props.height * (minScale - maxScale);

    // const viewOffsetX = 0;
    // const viewOffsetY = 0;

    this.state = {
      data: props.json.data,
      nodes: props.json.nodes,
      edges: props.json.edges,
      isStatic: props.isStatic || false,
      isVertical: props.isVertical || false,
      isDirected: props.isDirected || false,

      minScale,
      maxScale,
      scale: Math.max(minScale, Math.min(props.scale, maxScale)),

      viewOffsetX,
      viewOffsetY,
      viewOffsetOriginX: viewOffsetX,
      viewOffsetOriginY: viewOffsetY,

      isDragging: false,
    };
  }

  componentDidMount() {
    this.parentWidth =
      this.graphContainer && this.graphContainer.parentNode
        ? this.graphContainer.parentNode.clientWidth
        : 0;

    this.setState({ nodes: this._getNodesProps() });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.json && nextProps.json !== this.props.json) {
      this.setState(
        {
          data: nextProps.json.data,
          nodes: nextProps.json.nodes,
          edges: nextProps.json.edges,
          isStatic: nextProps.json.isStatic || false,
          isVertical: nextProps.json.isVertical || false,
          isDirected: nextProps.json.isDirected || false,
        },
        () => {
          this.setState({ nodes: this._getNodesProps() });
        }
      );
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { minScale, maxScale } = this.state;

    if (nextState.scale) {
      nextState.scale = Math.max(minScale, Math.min(nextState.scale, maxScale));
    }
  }

  _moveGroup(groupID, movementX, movementY) {
    const relatedGroupNodes = this.nodeComponents.filter(
      (n) => n.props.groupID && n.props.groupID === groupID
    );

    for (const key in relatedGroupNodes) {
      if (relatedGroupNodes.hasOwnProperty.call(relatedGroupNodes, key)) {
        const node = relatedGroupNodes[key];
        node.move(movementX, movementY);
      }
    }
  }

  _onChange(nextNode) {
    let { nodes } = this.state;
    const { json, onChange } = this.props;
    if (nextNode) {
      nodes = nodes.map((node) =>
        node.id === nextNode.id ? { ...node, ...nextNode } : node
      );
    }

    if (typeof onChange === "function") {
      json.nodes = nodes;
      onChange(json);
    }

    this.setState({ nodes });
  }

  _onWhell(event) {
    const {
      scale,
      minScale,
      maxScale,
      viewOffsetX,
      viewOffsetY,
      viewOffsetOriginX,
      viewOffsetOriginY,
    } = this.state;

    const direction = event.deltaY > 0 ? -1 : 1;
    const nextScale = parseFloat(
      (scale + direction * SCALE_STEP).toPrecision(2)
    );
    const scaleDelta = (scale - minScale) / SCALE_STEP;

    if (nextScale > maxScale || nextScale < minScale) {
      return;
    }

    const nextState = { scale: nextScale };

    if (scaleDelta) {
      nextState.viewOffsetX =
        viewOffsetX -
        (Math.abs(viewOffsetOriginX) - Math.abs(viewOffsetX)) / scaleDelta;
      nextState.viewOffsetY =
        viewOffsetY -
        (Math.abs(viewOffsetOriginY) - Math.abs(viewOffsetY)) / scaleDelta;
    }

    this.setState(nextState);
  }

  _onMouseDown(event) {
    // only left mouse button
    if (event.button !== 0) return;

    this.setState({ isDragging: true });
  }

  _onMouseMove(event) {
    const {
      scale,
      maxScale,
      minScale,
      viewOffsetX,
      viewOffsetY,
      isDragging,
      viewOffsetOriginX,
      viewOffsetOriginY,
    } = this.state;

    if (!isDragging) {
      return;
    }

    let scaleK = 0; // if scale => scaleMin then scaleK => 1;

    if (maxScale - minScale !== 0) {
      scaleK = (maxScale - scale) / (maxScale - minScale);
    }

    const { movementX, movementY } = event;

    let nextViewOffsetX = viewOffsetX + movementX;
    let nextViewOffsetY = viewOffsetY + movementY;

    const minX = scaleK * viewOffsetOriginX;
    const minY = scaleK * viewOffsetOriginY;
    const maxX = viewOffsetOriginX / (minScale / scale);
    const maxY = viewOffsetOriginY / (minScale / scale);

    nextViewOffsetX = nextViewOffsetX > minX ? minX : nextViewOffsetX;
    nextViewOffsetY = nextViewOffsetY > minY ? minY : nextViewOffsetY;

    nextViewOffsetX = nextViewOffsetX < maxX ? maxX : nextViewOffsetX;
    nextViewOffsetY = nextViewOffsetY < maxY ? maxY : nextViewOffsetY;

    this.setState({
      viewOffsetX: nextViewOffsetX,
      viewOffsetY: nextViewOffsetY,
    });
  }

  _onMouseUp() {
    this.setState({ isDragging: false });
  }

  _onMouseEnter(nodeID) {
    this.setState({ hoverNodeID: nodeID });
  }

  _onMouseLeave() {
    this.setState({ hoverNodeID: undefined });
  }

  // TODO: Call Node method
  _getNodesProps() {
    return this.nodeComponents.map(({ props }) => ({
      id: props.id,
      data: props.data,
      groupID: props.groupID,
      relationID: props.relationID,
      position: {
        x: props.x,
        y: props.y,
      },
      size: {
        width: props.width,
        height: props.height,
      },
    }));
  }

  toJSON() {
    return {
      nodes: this.nodeComponents.map((nodeComponent) => nodeComponent.toJSON()),
      edges: this.state.edges,
    };
  }

  renderNode(node) {
    const {
      Node: CustomNode,
      shouldNodeFitContent,
      onNodeSelect,
      json,
    } = this.props;
    const { width, height } = node.size || {};
    const { scale, isStatic } = this.state;
    const NodeComponent = CustomNode || Node;

    return (
      <div
        onMouseEnter={() => {
          this._onMouseEnter(node.id);
        }}
        onMouseLeave={() => {
          this._onMouseLeave();
        }}
      >
        <NodeComponent
          scale={scale}
          key={`node_${node.id}`}
          ref={(component) => component && this.nodeComponents.push(component)}
          getGraph={() => this.graphContainer}
          onChange={(nodeJSON) => {
            this._onChange(nodeJSON);
          }}
          // snap={snap}
          onNodeSelect={onNodeSelect}
          x={node.position ? node.position.x : 0}
          y={node.position ? node.position.y : 0}
          width={width}
          height={height}
          isStatic={isStatic}
          json={json}
          shouldFitContent={shouldNodeFitContent}
          moveGroup={this._moveGroup.bind(this)}
          {...node}
        />
      </div>
    );
  }

  render() {
    const {
      Edge: CustomEdge,
      width,
      height,
      style,
      onEdgeSelect,
      selectedNodeID,
      selectedInteractor,
      selectedNextStage,
      selectedNode,
      history,
    } = this.props;
    const EdgeComponent = CustomEdge || Edge;
    const {
      nodes,
      edges,

      isDirected,
      isVertical,
      isDragging,

      scale,
      minScale,
      viewOffsetX,
      viewOffsetY,
      hoverNodeID,
    } = this.state;

    const _edges = edges
      .map((edge) => ({
        source: nodes.find((node) => node.id === edge.source) || null,
        target: nodes.find((node) => node.id === edge.target) || null,
      }))
      .filter(Boolean);

    this.nodeComponents = [];

    return (
      <div style={{ ...styles.container, width, height }}>
        <div
          style={Object.assign({}, styles.root, style, {
            width: width / minScale,
            height: height / minScale,
            marginLeft: viewOffsetX,
            marginTop: viewOffsetY,
            cursor: isDragging ? "move" : "default",
            transform: `scale(${scale})`,
            transition: `transform .1s linear${
              isDragging ? "" : ", margin .1s linear"
            }`,
            transformOrigin: `${width}px ${height}px`,
          })}
          ref={(element) => {
            this.graphContainer = element;
          }}
          onWheel={(event) => {
            this._onWhell(event.nativeEvent);
          }}
          onMouseDown={(event) => {
            this._onMouseDown(event.nativeEvent);
          }}
          onMouseMove={(event) => {
            this._onMouseMove(event.nativeEvent);
          }}
          onMouseUp={() => {
            this._onMouseUp();
          }}
        >
          <div
            style={styles.nodes}
            ref={(element) => {
              this.htmlContainer = element;
            }}
          >
            {nodes.map((node) => this.renderNode(node))}
          </div>
          <svg
            ref={(element) => {
              this.svgContainer = element;
            }}
            style={styles.svg}
          >
            {_edges.map((edge) => {
              return (
                <EdgeComponent
                  key={`edge_${edge.source.id}_${edge.target.id}`}
                  source={edge.source}
                  highlighted={
                    hoverNodeID === edge.source.id ||
                    history.interactors.find(
                      (h) =>
                        h.source === edge.source.id &&
                        h.target === edge.target.id
                    ) ||
                    selectedNodeID === edge.source.id ||
                    `interactor_${selectedInteractor?.id}` === edge.source.id ||
                    (selectedNextStage &&
                      selectedNode &&
                      `stage_${selectedNextStage?.id}` === edge.target.id &&
                      `stage_${selectedNode?.groupID}` === edge.source.id)
                  }
                  lowOpacity={hoverNodeID || selectedNodeID}
                  onEdgeSelect={onEdgeSelect}
                  target={edge.target}
                  isDirected={isDirected || false}
                  isVertical={isVertical || false}
                />
              );
            })}
          </svg>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Graph);
