// import PropTypes from 'prop-types';
// material-ui
import { Grow, Icon, Paper, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Graph from "components/Graph";
import Node from "components/Node";
import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Pipeline({ pipeline, debug }) {
  const [graphDimension, setGraphDimension] = React.useState();
  const classes = useStyles(styles);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (pipeline) {
      let graphWidth = 0;
      let graphHeight = 0;
      for (const key in pipeline.nodes) {
        if (Object.hasOwnProperty.call(pipeline.nodes, key)) {
          const node = pipeline.nodes[key];
          graphWidth =
            Math.max(graphWidth, node.position.x + node.size.width) + 5;
          graphHeight = Math.max(
            graphHeight,
            node.position.y + node.size.height + 5
          );
        }
      }
      setGraphDimension({
        width: graphWidth + 100,
        height: graphHeight + 1000,
      });
    }
  }, [pipeline]);

  return (
    <div className={classes.root}>
      {graphDimension ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            position: "relative",
            justifyContent: "center",
          }}
        >
          {/* <Typography
            variant="h6"
            style={{ zIndex: 9999, background: "red", height: 0 }}
          >
            {pipeline?.name}
          </Typography> */}
          <ScrollContainer
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              overflow: "auto",
            }}
          >
            <Graph
              width={graphDimension.width}
              height={graphDimension.height}
              Node={GitNode}
              // snap={20}
              isStatic={true}
              // scale={0.8}
              // minScale={0.5}
              // maxScale={1}
              onNodeSelect={(sn) => console.log(sn)}
              // selectedNodeID={selectedNode?.id}
              shouldNodeFitContent
              isDirected={true}
              json={{ ...pipeline }}
              onChange={() => console.log("onChange")}
            />
          </ScrollContainer>
        </div>
      ) : (
        []
      )}
    </div>
  );
}

function Interactor({ node, onNodeSelect, debug }) {
  const selectedInteractor = useSelector((state) => state.selectedInteractor);
  let icon;

  switch (node.data.interactor.kind) {
    case "button":
      icon = "fad fa-hand-pointer";
      break;
    case "checkbox":
      icon = "fad fa-check-square";
      break;
    case "file":
      icon = "fad fa-file-alt";
      break;
    case "signature":
      icon = "fal fa-signature";
      break;
    case "text":
      icon = "far fa-text";
      break;
    default:
      icon = "fad fa-hand-pointer";
      break;
  }

  return (
    <div
      onDoubleClick={() => onNodeSelect(node)}
      style={{
        color:
          `interactor_${selectedInteractor?.id}` === node.id
            ? "white"
            : "#78909c",
        justifyContent: "space-between",
        border: `solid 1px ${
          `interactor_${selectedInteractor?.id}` === node.id
            ? selectedInteractor.color
            : "#78909c"
        }`,
        alignItems: "center",
        display: "flex",
        borderRadius: 20,
        position: "relative",
        cursor: "default",
        width: 158,
        background:
          `interactor_${selectedInteractor?.id}` === node.id
            ? selectedInteractor.color
            : "rgba(255,255,255,0.1)",
        backdropFilter: "blur(10px)",
        WebkitBackdropFilter: "blur(10px)",
      }}
    >
      {icon ? (
        <Tooltip title={node.data.interaction || "button"}>
          <Icon className={icon} style={{ fontSize: 14, paddingLeft: 10 }} />
        </Tooltip>
      ) : (
        <div style={{ width: 14, paddingLeft: 8 }} />
      )}
      <div style={{ flexGrow: 1, textAlign: "center" }}>
        {node.data.interactor.name}{" "}
        {node.data.interactor.mandatory ? (
          <sup style={{ position: "absolute" }}>*</sup>
        ) : (
          []
        )}
      </div>
      <div style={{ width: 14, paddingRight: 8 }} />
      {debug ? (
        <div
          style={{
            position: "absolute",
            right: -15,
            fontSize: 9,
          }}
        >
          {node.data.interactor.id}
        </div>
      ) : (
        []
      )}
    </div>
  );
}

function Stage({ node, onNodeSelect, debug }) {
  const dispatch = useDispatch();
  const selectedNode = useSelector((state) => state.selectedNode);
  const selectedNextStage = useSelector((state) => state.selectedNextStage);
  const history = useSelector((state) => state.history);
  const nudgeCount =
    history.nodes.reduce((a, v) => {
      if (v.nudge && v.id === node.id) a.push(v);
      return a;
    }, [])?.length || "";

  const highlighted =
    selectedNode?.id === node.id ||
    (`stage_${selectedNextStage?.id}` === node.id &&
      selectedNextStage.primary) ||
    history.nodes.find((h) => h.id === node.id);

  return (
    <Paper
      elevation={0}
      style={{
        width: 150,
        height: 20,
        background: highlighted ? node.data.stage.color : undefined,
        color: highlighted ? "white" : undefined,
        boxShadow: "0px 4px 10px rgb(96,125,139,0.3)",
        borderRadius: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
        cursor: "default",
        padding: 4,
      }}
    >
      {debug && node.data.stage.primary ? (
        <div
          style={{ position: "absolute", left: -20, top: 18, color: "white" }}
        >
          <i class="fas fa-long-arrow-right"></i>
        </div>
      ) : (
        []
      )}
      {debug && node.data.stage.manual ? (
        <div style={{ position: "absolute", left: -20, color: "white" }}>
          <Tooltip title="Manual Trigger">
            <div>
              <i class="fas fa-hand-point-up"></i>
            </div>
          </Tooltip>
        </div>
      ) : (
        []
      )}
      <img
        src={node.data.stage.icon}
        alt={"icon"}
        style={{
          height: 20,
          width: 20,
          filter: highlighted ? "brightness(0) invert(100%)" : undefined,
        }}
      />
      <Typography variant="body2">{node.data.stage.name}</Typography>
      <div style={{ display: "flex", paddingRight: 4 }}>
        {debug && node.data.stage.silent ? (
          <Tooltip title="Silent Stage">
            <div style={{ color: "#9e9e9e" }}>
              <i class="fas fa-ghost"></i>
            </div>
          </Tooltip>
        ) : (
          <div />
        )}
        {debug && node.data.stage.nudgeable ? (
          <Tooltip title="Nudgeable">
            <div style={{ color: "#9e9e9e" }}>
              <i class="fas fa-bullhorn"></i>
            </div>
          </Tooltip>
        ) : (
          <div />
        )}
      </div>
      {debug ? (
        <div
          style={{
            position: "absolute",
            right: -15,
            color: "white",
            fontSize: 9,
          }}
        >
          {node.data.stage.id}
        </div>
      ) : (
        []
      )}
      {nudgeCount ? (
        <span style={{ position: "absolute", right: 4, fontSize: 12 }}>
          <i class="fad fa-bullhorn"></i> {nudgeCount}
        </span>
      ) : (
        []
      )}
    </Paper>
  );
}

class GitNode extends Node {
  renderContainer({ json, node, onNodeSelect }) {
    if (node.data.type === "stage") {
      return (
        <Grow in>
          <Stage node={node} onNodeSelect={onNodeSelect} />
        </Grow>
      );
    }

    if (node.data.type === "interactor") {
      return (
        <Grow in>
          <Interactor node={node} onNodeSelect={onNodeSelect} />
        </Grow>
      );
    }
  }
}
