import { combineReducers } from "redux"; //eslint-disable-line
import app from "./app";
import displayNode from "./displayNode";
import history from "./history";
import pipeline from "./pipeline";
import pipelineGraph from "./pipelineGraph";
import selectedInteractor from "./selectedInteractor";
import selectedNextStage from "./selectedNextStage";
import selectedNode from "./selectedNode";
import user from "./user";
export default combineReducers({
  user,
  app,
  pipeline,
  pipelineGraph,
  selectedNode,
  selectedInteractor,
  selectedNextStage,
  history,
  displayNode,
});
