import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "mercury";
const stages = `${REACT_APP_API_PREFIX}/${service}/stages`;

export function getStages(filters) {
  const url = `${stages}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getStageById(id) {
  const url = `${stages}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateStage(id, body) {
  const url = `${stages}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteStage(id) {
  const url = `${stages}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createStage(body) {
  const url = `${stages}`;
  return {
    method: POST,
    url,
    body,
  };
}
