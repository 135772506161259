export default {
  root: {
    strokeWidth: ".5px",
    stroke: "#90a4ae",
    opacity: 0.3,
    cursor: "pointer",
    fill: "transparent",
    transition: "opacity 0.2s, stroke 0.2s, strokeWidth 0.2s",
    WebkitTransition: "opacity 0.2s, stroke 0.2s, strokeWidth 0.2s",
  },
};
