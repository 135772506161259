const { _APP_CONFIG } = window;

// api
export const REACT_APP_API = _APP_CONFIG.api.endpoint;
export const REACT_APP_API_PREFIX = _APP_CONFIG.api.routePrefix;

// theme
export const REACT_APP_PRIMARY_COLOR = _APP_CONFIG.theme.primaryColor;
export const REACT_APP_SECONDARY_COLOR = _APP_CONFIG.theme.secondaryColor;

// app
export const REACT_APP_LOGO = _APP_CONFIG.app.logo;
export const INSTRUCTIONS = _APP_CONFIG.app.instructions;
export const DESCRIPTION = _APP_CONFIG.app.description;
export const SUBTITLE = _APP_CONFIG.app.subtitle;
export const TOUR = _APP_CONFIG.app.tour;
export const ROLE = _APP_CONFIG.app.role;
export const HEADER = _APP_CONFIG.app.emailHeader;
export const PREVIEW = _APP_CONFIG.app.preview;
export const REACT_APP_FAVICON = _APP_CONFIG.app.favicon;

// centrifugo
export const CENTRIFUGO_URL = _APP_CONFIG.centrifugo.url;
export const CENTRIFUGO_TOKEN = _APP_CONFIG.centrifugo.token;
