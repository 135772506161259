import {
  SET_DARK_MODE,
  SET_DISPLAY_NODE,
  SET_HISTORY,
  SET_SELECTED_INTERACTOR,
  SET_SELECTED_NEXT_STAGE,
  SET_SELECTED_NODE,
} from "constants/app";

export const setDarkMode = (token) => async (dispatch) =>
  dispatch({
    type: SET_DARK_MODE,
    token,
  });
export const setSelectedNode = (value) => async (dispatch) =>
  dispatch({
    type: SET_SELECTED_NODE,
    value,
  });

export const setSelectedInteractor = (value) => async (dispatch) =>
  dispatch({
    type: SET_SELECTED_INTERACTOR,
    value,
  });

export const setSelectedNextStage = (value) => async (dispatch) =>
  dispatch({
    type: SET_SELECTED_NEXT_STAGE,
    value,
  });

export const setHistory = (value) => async (dispatch) =>
  dispatch({
    type: SET_HISTORY,
    value,
  });

export const setDisplayNode = (value) => async (dispatch) =>
  dispatch({
    type: SET_DISPLAY_NODE,
    value,
  });
