// import PropTypes from 'prop-types';
// material-ui
import {
  Badge,
  Chip,
  Grid,
  Grow,
  Hidden,
  Paper,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as Actions from "actions";
import { HEADER, ROLE } from "config";
import Interactor from "hooks/Interactor";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Mail from "./mail.png";
import Phone from "./phone.png";
import Rhapsody from "./rhapsody.png";
import Sms from "./sms.png";
// styles
import styles from "./styles";
import Musician from "./violin.png";

const useStyles = makeStyles(styles);

export default function InteractionPreview() {
  const [stage, setStage] = React.useState();
  const [displayStage, setDisplayStage] = React.useState();
  const [isTourOpen, setIsTourOpen] = React.useState(false);
  const [tourShowed, setTourShowed] = React.useState(false);
  const [height, setHeight] = React.useState(0);
  const [silentMessage, setSilentMessage] = React.useState(false);
  const [available, setAvailable] = React.useState();
  const [primaries, setPrimaries] = React.useState();
  const classes = useStyles(styles);
  const selectedNode = useSelector((state) => state.selectedNode);
  const displayNode = useSelector((state) => state.displayNode);
  const pipeline = useSelector((state) => state.pipeline);
  const history = useSelector((state) => state.history);
  const dispatch = useDispatch();
  const ref = React.useRef();

  React.useEffect(() => {
    if (selectedNode) {
      const stage = pipeline.stages.find((s) => s.id === selectedNode.groupID);
      setStage(stage);

      const available = pipeline.stages.reduce((a, v) => {
        let av = v.available.split(",");
        let ex = v.excluded.split(",");
        if (
          av.find((i) => Number(i) === stage.id) ||
          (av.find((i) => i === "*") && !ex.find((i) => Number(i) === stage.id))
        ) {
          a.push(v);
        }
        return a;
      }, []);
      setAvailable(available);
    }

    if (selectedNode === null) {
      setStage(null);
      setAvailable(null);
    }
  }, [selectedNode]);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  React.useEffect(() => {
    if (displayNode) {
      const stage = pipeline.stages.find((s) => s.id === displayNode.groupID);
      setDisplayStage(stage);
    }

    if (selectedNode === null) {
      setDisplayStage(null);
    }
  }, [displayNode]);

  React.useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight * 0.8);
    }
  });

  React.useEffect(() => {
    if (pipeline) {
      const primaries = pipeline.stages.reduce((a, v) => {
        if (v.primary) a.push(v);
        return a;
      }, []);
      setPrimaries(primaries);
    }
  }, [pipeline]);

  const handleInteractorClick = (i) => () => {
    if (i.segueID) {
      const nextStage = pipeline.stages.find((s) => s.id === i.segueID);
      if (!nextStage.silent) {
        dispatch(
          Actions.setSelectedNode({
            groupID: i.segueID,
            id: `stage_${i.segueID}`,
          })
        );
      }

      dispatch(
        Actions.setDisplayNode({
          groupID: i.segueID,
          id: `stage_${i.segueID}`,
        })
      );

      const _history = { ...history };
      _history.interactors.push({
        source: `interactor_${i.id}`,
        target: `stage_${i.segueID}`,
      });

      _history.nodes.push({
        id: `stage_${i.segueID}`,
      });

      dispatch(Actions.setHistory(_history));
      dispatch(Actions.setSelectedInteractor(null));
    }
  };

  function handleRestart() {
    dispatch(Actions.setSelectedNode(null));
    dispatch(Actions.setSelectedInteractor(null));
    dispatch(Actions.setDisplayNode(null));
    dispatch(Actions.setSelectedNextStage(null));
    dispatch(Actions.setHistory({ nodes: [], interactors: [] }));
  }

  function handleNudge(i) {
    const _history = { ...history };
    _history.interactors.push({
      source: `${selectedNode?.id}`,
      target: `stage_${i.id}`,
    });

    _history.nodes.push({
      id: `stage_${i.id}`,
      nudge: true,
    });
    dispatch(Actions.setHistory(_history));
  }

  const handleNextClick = (i) => () => {
    const _history = { ...history };
    if (!selectedNode && !tourShowed) {
      setIsTourOpen(true);
      setTourShowed(true);
    }
    _history.interactors.push({
      source: `${selectedNode?.id}`,
      target: `stage_${i.id}`,
    });

    _history.nodes.push({
      id: `stage_${i.id}`,
    });
    dispatch(Actions.setHistory(_history));
    if (!i.silent) {
      setSilentMessage(false);
      dispatch(
        Actions.setSelectedNode({
          groupID: i.id,
          id: `stage_${i.id}`,
        })
      );
      dispatch(
        Actions.setDisplayNode({
          groupID: i.id,
          id: `stage_${i.id}`,
        })
      );
      dispatch(Actions.setSelectedInteractor(null));
    } else {
      dispatch(
        Actions.setDisplayNode({
          groupID: i.id,
          id: `stage_${i.id}`,
        })
      );
    }
  };

  const sortedInteractors = displayStage?.interactors?.sort(
    (a, b) => a.segueID || 0 - b.segueID || 0
  );

  const nudgeCount =
    history.nodes.reduce((a, v) => {
      if (v.nudge && v.id === `stage_${stage.id}`) a.push(v);
      return a;
    }, [])?.length || "";

  return (
    <>
      <div className={classes.container}>
        <div
          style={{
            display: "flex",
            flex: 1,
            width: "100%",
            maxWidth: 600,
            justifyContent: "center",
          }}
        >
          <Hidden xsDown>
            <div
              style={{
                width: 70,
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ padding: "12px 0px" }}>
                <Tooltip title="Call is sent by Email">
                  <Badge
                    badgeContent={
                      nudgeCount || displayStage?.interactors?.length ? (
                        <span style={{ color: "white" }}>{nudgeCount + 1}</span>
                      ) : undefined
                    }
                    color="error"
                  >
                    <img src={Mail} alt="" style={{ height: 45 }} />
                  </Badge>
                </Tooltip>
              </div>
              <div style={{ padding: "12px 0px" }}>
                <Tooltip title="Call is sent by SMS">
                  <Badge
                    badgeContent={
                      nudgeCount || displayStage?.interactors?.length ? (
                        <span style={{ color: "white" }}>{nudgeCount + 1}</span>
                      ) : undefined
                    }
                    color="error"
                  >
                    <img src={Sms} alt="" style={{ height: 45 }} />
                  </Badge>
                </Tooltip>
              </div>
              <div style={{ padding: "12px 0px" }}>
                <Tooltip title="Call is sent by Push Notifications">
                  <Badge
                    badgeContent={
                      nudgeCount || displayStage?.interactors?.length ? (
                        <span style={{ color: "white" }}>{nudgeCount + 1}</span>
                      ) : undefined
                    }
                    color="error"
                  >
                    <img src={Rhapsody} alt="" style={{ height: 45 }} />
                  </Badge>
                </Tooltip>
              </div>
            </div>
          </Hidden>
          <div
            style={{
              flexGrow: 1,
              width: "100%",
              position: "relative",
            }}
          >
            {displayStage ? (
              <Grow in>
                <Paper
                  data-tut="reactour__paper"
                  ref={ref}
                  style={{
                    flex: 1,
                    width: !sm ? "calc(100% - 20px)" : "calc(100% - 100px)",
                    borderRadius: !sm ? 4 : 20,
                    overflow: "hidden",
                    paddingBottom: 500,
                    right: 0,
                    zIndex: 99,
                    top: 80,
                    position: "absolute",
                    left: !sm ? 10 : 50,
                  }}
                >
                  <div style={{ position: "relative" }}>
                    {HEADER ? (
                      <img src={HEADER} style={{ width: "100%" }} />
                    ) : (
                      []
                    )}
                    <Grid
                      container
                      spacing={1}
                      justify="center"
                      style={{ flex: 1, padding: 20 }}
                    >
                      <Grid item style={{ display: "flex" }}>
                        <img
                          src={displayStage?.icon}
                          style={{ height: 20, paddingRight: 10 }}
                        />
                        <Typography
                          variant="h6"
                          style={{ color: displayStage?.color }}
                        >
                          {displayStage?.name}
                        </Typography>
                      </Grid>
                      <div style={{ height: 0, flexBasis: "100%" }} />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        {sortedInteractors?.map((i, k) => {
                          const JSX = [];
                          if (
                            Number(k) > 0 &&
                            Number(k) <= sortedInteractors.length - 1
                          ) {
                            const prev = sortedInteractors[Number(k - 1)];
                            if (!prev.segueID) {
                              JSX.push(
                                <div
                                  style={{ height: 10, flexBasis: "100%" }}
                                />
                              );
                            }
                          }
                          JSX.push(
                            <div
                              style={{ flex: 1, display: "flex" }}
                              onMouseEnter={() => {
                                dispatch(Actions.setSelectedInteractor(i));
                              }}
                              onMouseLeave={() => {
                                dispatch(Actions.setSelectedInteractor(null));
                              }}
                            >
                              <Interactor
                                key={i.id}
                                interactor={i}
                                handleMusicianAnswer={() =>
                                  console.log("handleMusicianAnswer")
                                }
                                setGrabber={() => console.log("Grabber")}
                                onSubmit={(s) => handleInteractorClick(s)()}
                              />
                            </div>
                          );
                          return JSX;
                        })}
                      </div>
                    </Grid>
                    {sortedInteractors.length === 0 &&
                    available?.length !== 0 ? (
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <i
                          class="fad fa-thumbs-up"
                          style={{ fontSize: 40 }}
                        ></i>
                        <br />
                        <Typography>All set!</Typography>
                        <Typography variant="caption" color="textSecondary">
                          Musician waits for contractor's next action.
                        </Typography>
                      </Grid>
                    ) : (
                      []
                    )}
                    {displayStage.silent ? (
                      <div style={{ textAlign: "center" }}>
                        <Chip
                          onClick={() => {
                            dispatch(Actions.setDisplayNode(selectedNode));
                          }}
                          size="small"
                          label="Mark as Read"
                        />
                      </div>
                    ) : (
                      []
                    )}
                    {available?.length === 0 &&
                    sortedInteractors?.length === 0 ? (
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <i
                          class="far fa-flag-checkered"
                          style={{ fontSize: 40 }}
                        ></i>
                        <br />
                        <Typography>End of the pipeline</Typography>
                        <Typography variant="caption" color="textSecondary">
                          The communication is over
                        </Typography>
                      </Grid>
                    ) : (
                      []
                    )}
                  </div>
                </Paper>
              </Grow>
            ) : (
              []
            )}
            <div
              style={{
                width: "100%",
                borderRadius: 8,
                flexGrow: 1,
                paddingBottom: 40,
                minHeight: sm ? 500 : 600,
                position: "relative",
              }}
            >
              <Hidden xsDown>
                <img
                  src={Phone}
                  alt={"phone"}
                  style={{
                    width: "calc(100% + 60px)",
                    position: "absolute",
                    left: -30,
                    top: -20,
                  }}
                />
              </Hidden>
            </div>
          </div>
          <Hidden xsDown>
            <div
              style={{
                width: 70,
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
                paddingLeft: 10,
              }}
            >
              <img src={Musician} style={{ width: "100% " }} />
            </div>
          </Hidden>
        </div>
        <Paper
          style={{
            position: "fixed",
            padding: 10,
            borderRadius: 0,
            bottom: 0,
            zIndex: 999,
            width: "100vw",
            background: "rgba(0,0,0,0.6)",
            color: "white",
            backdropFilter: "blur(10px)",
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div style={{ flex: 1, textAlign: "center" }}>
            <Typography variant="h6">
              <b>{ROLE} Possible Actions</b>:
            </Typography>
            {/* <Typography
                  variant="caption"
                  color="textSecondary"
                  gutterBottom
                >
                  Notify a musician or a group
                </Typography> */}
          </div>
          <Hidden mdUp>
            <div style={{ flexBasis: "100%", height: 0 }} />
          </Hidden>
          <div style={{ flex: 2 }}>
            <Grid container spacing={1} justify="center">
              {available?.map((a) => (
                <Grid item>
                  <Chip
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          alt="invite"
                          style={{ height: 15, paddingRight: 4 }}
                          src={a.icon}
                        />
                        {a.name}
                      </div>
                    }
                    variant="outlined"
                    style={{ background: "white" }}
                    onMouseEnter={() => {
                      dispatch(Actions.setSelectedNextStage(a));
                    }}
                    onMouseLeave={() => {
                      dispatch(Actions.setSelectedNextStage(null));
                    }}
                    onClick={handleNextClick(a)}
                  />
                </Grid>
              ))}
              {stage?.nudgeable ? (
                <Grid item>
                  <Chip
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <i
                          style={{ paddingRight: 4, color: stage.color }}
                          class="fad fa-bullhorn"
                        ></i>
                        {stage.name} Nudge{" "}
                      </div>
                    }
                    variant="outlined"
                    style={{ background: "white" }}
                    onMouseEnter={() => {
                      dispatch(Actions.setSelectedNextStage(stage));
                    }}
                    onMouseLeave={() => {
                      dispatch(Actions.setSelectedNextStage(null));
                    }}
                    onClick={() => handleNudge(stage)}
                  />
                </Grid>
              ) : (
                []
              )}
              {!selectedNode
                ? primaries?.map((a) => (
                    <Grid item>
                      <Chip
                        label={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              alt="invite"
                              style={{ height: 15, paddingRight: 4 }}
                              src={a.icon}
                            />
                            {a.name}
                          </div>
                        }
                        variant="outlined"
                        style={{ background: "white" }}
                        onMouseEnter={() => {
                          dispatch(Actions.setSelectedNextStage(a));
                        }}
                        onMouseLeave={() => {
                          dispatch(Actions.setSelectedNextStage(null));
                        }}
                        onClick={handleNextClick(a)}
                      />
                    </Grid>
                  ))
                : []}
            </Grid>
          </div>
          <Hidden xsDown>
            <div style={{ flex: 1, textAlign: "center" }}>
              <Chip
                size="small"
                onClick={handleRestart}
                style={{ background: "#f44336", color: "white" }}
                label={
                  <span>
                    <i class="fas fa-undo"></i> Restart Simulation
                  </span>
                }
              />
            </div>
          </Hidden>
        </Paper>
      </div>
    </>
  );
}
