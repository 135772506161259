export default (theme) => {
  const darkMode = theme.palette.type === "dark";
  return {
    root: {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "center",
      flexDirection: "column",
      flex: 1,
      background:
        "linear-gradient(180deg, rgba(246,249,252,1) 0%, rgba(204,214,223,0.7) 100%)",
    },
    container: {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "center",
      flexDirection: "column",
      flex: 1,
    },
    item1: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    pipeline: {
      flexGrow: 1,
      display: "flex",
    },
    item2: {
      flex: 1,
      width: "100vw",
      marginLeft: "auto",
      marginRight: "auto",
      position: "fixed",
      bottom: -10,
      justifyContent: "center",
      display: "flex",
      alignItems: "flex-end",
    },
  };
};
