import {
  Button,
  Checkbox,
  Icon,
  IconButton,
  InputBase,
  Tooltip,
} from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import Signature from "components/Signature";
import React from "react";
import { useDropzone } from "react-dropzone";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Interactor({ interactor, setGrabber, onSubmit }) {
  const [sign, setSign] = React.useState(() => (x) => console.log("Hello"));
  const [fileCount, setFileCount] = React.useState(0);
  const [check, setCheck] = React.useState(false);
  const [text, setText] = React.useState("");
  const [signatureDone, setSignatureDone] = React.useState(false);
  const useStyles = makeStyles(styles);
  const classes = useStyles(styles);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const grabber = () => {
    switch (interactor.kind) {
      case "checkbox":
        return { interactor, value: check };
      case "text":
        return { interactor, value: text };
      case "signature":
        return { interactor, value: signatureDone && sign()() };
      case "file":
        return { interactor, value: acceptedFiles };
      default:
        break;
    }
  };

  React.useEffect(() => {
    setGrabber(grabber, interactor);
  });

  const files = acceptedFiles;

  switch (interactor.kind) {
    case "button":
      return (
        <Tooltip title={interactor.description}>
          <Button
            style={{
              margin: 4,
              flex: 1,
              color: "white",
              background: interactor.color,
              textTransform: "none",
            }}
            onClick={() => onSubmit(interactor)}
            variant="contained"
          >
            {interactor.icon ? (
              <i class={interactor.icon} style={{ paddingRight: 8 }}></i>
            ) : (
              []
            )}
            {interactor.title}
            {interactor.mandatory ? (
              <span style={{ fontSize: 10, opacity: 0.58 }}> - Required</span>
            ) : (
              []
            )}
          </Button>
        </Tooltip>
      );
    case "text":
      return (
        <div
          style={{
            flex: 1,
            padding: 8,
            borderRadius: 8,
          }}
        >
          <Typography variant="body2" color="inherit">
            {interactor.icon ? (
              <i class={interactor.icon} style={{ paddingRight: 8 }}></i>
            ) : (
              []
            )}
            {interactor.title}
            {interactor.mandatory ? (
              <span style={{ fontSize: 10, opacity: 0.58 }}> - Required</span>
            ) : (
              []
            )}
          </Typography>
          <div
            className={classes.interactor}
            style={{
              flex: 1,
            }}
          >
            <InputBase
              style={{ flex: 1, fontSize: 16, color: "inherit" }}
              fullWidth
              variant="outlined"
              multiline
              rowsMax={5}
              value={text}
              onChange={(e) => setText(e.target.value)}
              label={
                <span>
                  {interactor.icon ? <i class={interactor.icon}></i> : []}{" "}
                  {interactor.title}
                </span>
              }
              size="small"
              placeholder={interactor.description}
            />
          </div>
        </div>
      );
    case "signature":
      return (
        <div
          style={{
            flex: 1,
            padding: 8,
            borderRadius: 8,
          }}
        >
          <Typography variant="body2" color="inherit">
            {interactor.icon ? (
              <i class={interactor.icon} style={{ paddingRight: 8 }}></i>
            ) : (
              []
            )}
            {interactor.title}
            {interactor.mandatory ? (
              <span style={{ fontSize: 10, opacity: 0.58 }}> - Required</span>
            ) : (
              []
            )}
          </Typography>
          <div
            style={{
              flex: 1,
              padding: 0,
            }}
            className={classes.interactor}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
              }}
            >
              <Typography
                variant="caption"
                color="inherit"
                style={{ position: "absolute", padding: 6, opacity: 0.58 }}
              >
                {interactor.description}
              </Typography>
              <Signature
                clearSignature={() => {
                  setSignatureDone(false);
                }}
                signatureHasChanged={() => {
                  setSignatureDone(true);
                }}
                getSignature={(f) => {
                  setSign(() => () => f);
                }}
                color={interactor.color}
              />
            </div>
          </div>
        </div>
      );
    case "download":
      return (
        <div
          style={{
            flex: 1,
            padding: 8,
            borderRadius: 8,
          }}
        >
          <Typography variant="body2" color="inherit">
            {interactor.icon ? (
              <i class={interactor.icon} style={{ paddingRight: 8 }}></i>
            ) : (
              []
            )}
            {interactor.title}
            {interactor.mandatory ? (
              <span style={{ fontSize: 10, opacity: 0.58 }}> - Required</span>
            ) : (
              []
            )}
          </Typography>
          <div
            className={classes.interactor}
            style={{
              flex: 1,
            }}
          >
            <div style={{ flex: 2 }}>
              <Typography
                variant="caption"
                color="inherit"
                style={{ opacity: 0.58 }}
              >
                {interactor.description}
              </Typography>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              <Button
                style={{ textTransform: "none" }}
                size="small"
                fullWidth
                variant="outlined"
                onClick={() => window.open(interactor.data, "_blank")}
              >
                <i
                  style={{ paddingRight: 8 }}
                  class="fad fa-cloud-download-alt"
                ></i>{" "}
                Download
              </Button>
            </div>
          </div>
        </div>
      );

    case "checkbox":
      return (
        <div
          style={{
            flex: 1,
            padding: 8,
            borderRadius: 8,
          }}
        >
          <Typography variant="body2" color="inherit">
            {interactor.icon ? (
              <i class={interactor.icon} style={{ paddingRight: 8 }}></i>
            ) : (
              []
            )}
            {interactor.title}
            {interactor.mandatory ? (
              <span style={{ fontSize: 10, opacity: 0.58 }}> - Required</span>
            ) : (
              []
            )}
          </Typography>
          <div
            className={classes.interactor}
            style={{
              flex: 1,
            }}
          >
            <div style={{ flex: 2 }}>
              <Typography
                variant="caption"
                color="inherit"
                style={{ opacity: 0.58 }}
              >
                {interactor.description}
              </Typography>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              <Checkbox
                style={{ color: interactor.color || "inherit" }}
                checked={check}
                onChange={(e) => setCheck(e.target.checked)}
              />
            </div>
          </div>
        </div>
      );
    case "file":
      return (
        <div
          style={{
            flex: 1,
            padding: 8,
            borderRadius: 8,
          }}
        >
          <Typography variant="body2" color="inherit">
            {interactor.icon ? (
              <i class={interactor.icon} style={{ paddingRight: 8 }}></i>
            ) : (
              []
            )}
            {interactor.title}
            {interactor.mandatory ? (
              <span style={{ fontSize: 10, opacity: 0.58 }}> - Required</span>
            ) : (
              []
            )}
          </Typography>
          <div
            className={classes.interactor}
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            {files.map((f) => (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "solid 1px rgba(155,155,155,0.3)",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h4" color="inherit">
                    <i class="fad fa-file-alt"></i>
                  </Typography>
                  <div style={{ paddingLeft: 20 }}>
                    <div style={{ display: "flex" }}>
                      <Typography variant="caption" color="inherit">
                        {f.name}
                      </Typography>
                    </div>
                    <Typography
                      variant="caption"
                      color="inherit"
                      style={{ fontSize: 10, opacity: 0.58 }}
                    >
                      {f.type}
                    </Typography>
                  </div>
                </div>
                <IconButton
                  size="small"
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    const newFiles = [...files];
                    acceptedFiles.splice(0, 1);
                    setFileCount(fileCount + 1);
                  }}
                >
                  <Icon fontSize="inherit" className="far fa-times-circle" />
                </IconButton>
              </div>
            ))}
            <div
              {...getRootProps({ className: "dropzone" })}
              style={{
                display: "flex",
                flex: 1,
                cursor: "pointer",
                outlineWidth: 0,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h4"
                  color="inherit"
                  style={{ opacity: 0.58 }}
                >
                  <i class="fad fa-cloud-upload"></i>
                </Typography>
                <div style={{ paddingLeft: 20 }}>
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="caption"
                      color="inherit"
                      style={{ opacity: 0.58 }}
                    >
                      {interactor.description}
                    </Typography>
                  </div>
                  <input {...getInputProps()} />
                  <Typography
                    variant="caption"
                    color="inherit"
                    style={{ fontSize: 10, opacity: 0.58 }}
                  >
                    Drag and drop here or browse files
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    default:
      break;
  }
  return <Alert severity="error">Unknown interactor</Alert>;
}
