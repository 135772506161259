import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "mercury";
const interactors = `${REACT_APP_API_PREFIX}/${service}/interactors`;

export function getInteractors(filters) {
  const url = `${interactors}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getInteractorById(id) {
  const url = `${interactors}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateInteractor(id, body) {
  const url = `${interactors}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteInteractor(id) {
  const url = `${interactors}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createInteractor(body) {
  const url = `${interactors}`;
  return {
    method: POST,
    url,
    body,
  };
}
